import React, {useState} from "react";
import {requestPasswordReset} from "../helpers/api";
import {useHistory} from "react-router-dom";

export default function ResetPasswordContainer(props) {

    const [email, setEmail] = useState('')
    const history = useHistory();


    const resetPassword = async () =>{
        console.log('requesting New Password')
        const response = await requestPasswordReset({email:email})
        console.log(response)
        history.push(`setNewPassword/?resetToken=${response.token}&email=${email}`)
    }
    return (
        <div>
            <div>
                <p>Forgot your password?</p>
                <p>Enter your email.</p>
            </div>

        <fieldset>
            <label className={'login login__label login__label__email'} htmlFor={"email"}>Email</label>
            <input className={'login login__input login__input__email'}
                   name="emailAddress"
                   type="text"
                   value={email}
                   onChange={(event) => setEmail(event.target.value)}
            />
            <button onClick={() =>resetPassword()}>Submit</button>
        </fieldset>
    </div>
    )
}