import {setIdToken} from "../helpers/api";

export const checkForToken = () =>{
    // check for a stored token.  If there is one, you are logged in.
    const storedToken = localStorage.getItem('token')
    // set it in the API for use
    if (storedToken){
        const parts = storedToken.split('.')
        console.log(parts[1])
        const payload = JSON.parse(atob(parts[1]))

        // expiration - 1 hour
        const expiration = payload.exp * 100 - ( 60 * 60 * 1000)
        if (expiration < Date.now() ){
            // This expires in the future, go ahead and log in
            setIdToken(storedToken)
        }

    }
    return storedToken
}