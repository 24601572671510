import React, {useState} from "react";
import {updagePassword} from "../helpers/api";


export default function AccountContainer(props) {

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [status, setStatus] = useState()

    const changePassword = async () =>{
        if (newPassword.length < 8){
            setStatus("New password must be 8 characters")
            setTimeout(() => setStatus(null), 5000)
            return
        }
        if (!oldPassword){
            setStatus('Please enter your old password')
            setTimeout(() => setStatus(null), 5000)
            return
        }
        if (newPassword !== newPasswordConfirm){
            setStatus("Passwords must match")
            setTimeout(() => setStatus(null), 5000)
            return
        }

        updagePassword({oldPassword, newPassword}).then(response =>{
            console.log(response)
            setStatus('Password Changed Successfully')
            setTimeout(() => setStatus(null), 5000)
            setOldPassword('')
            setNewPassword('')
            setNewPasswordConfirm('')
        }).catch(() =>{
            setStatus('Invalid Password')
            setTimeout(() => setStatus(null), 5000)
            return null
        })


    }
    return (

        <div>
            <fieldset>
                <h1>Reset Your Password</h1>
                {status && <h2>{status}</h2>}
                <label htmlFor='oldPassword'>Current Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name='oldPassword'
                    value={oldPassword}
                    onChange={event => {setOldPassword(event.target.value)}}
                />
                <label htmlFor='newPassword'>New Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name='newPassword'
                    value={newPassword}
                    onChange={event => {setNewPassword(event.target.value)}}
                />
                <label htmlFor='newPasswordConfirm'>Confirm New Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name='newPasswordConfirm'
                    value={newPasswordConfirm}
                    onChange={event => {setNewPasswordConfirm(event.target.value)}}
                />
                <button onClick={() => setShowPassword(!showPassword)}>Show Password</button>
                <button onClick={() => changePassword()}>Change Password</button>
            </fieldset>
        </div>
    )

}