import {useEffect, useState} from "react";
import {getInTransit} from "../helpers/api";
import MappedTable from "../components/MappedTable";
import {HEADERS} from "../constants/headers";
import {checkForToken} from "../utils/login";
import Login from "../components/Login";

export default function InTransit() {
    const storedToken = checkForToken()
    const [token, setToken] = useState(storedToken)

    const [inTransit, setInTransit] = useState({})
    const inTransitHeaders = HEADERS.inTransit

    useEffect(() => {
        const fetchData = async () => {
            setInTransit(await getInTransit())
        }
        if (token) {
            fetchData()
        }

    }, [token])

    return (
        <div className="App">
            {token
                ?
                <div>
                    <MappedTable data={inTransit.shipments} propertyNames={inTransitHeaders}/>
                    <button onClick={async () => setInTransit(await getInTransit())}>Get In Transit</button>
                </div>
                :
                <Login setLoggedin={token => setToken(token)}/>
            }
        </div>
    )
}