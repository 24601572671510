import React from 'react'
import { useState } from "react";
import { login, setIdToken } from "../helpers/api";
import companyLogo from '../assets/img/logo.png';

export default function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [ rememberMe, setRememberMe ]   = useState(false);


    const handleLogin = async () => {
        const response = await login(username, password).catch(
            reason =>{
                return null
            }
        );
        if (!response){
            alert('invalid login')
            setPassword('')
            setUsername('')
            return
        }
        if(rememberMe) {
            localStorage.setItem('token', response.result.token)
        }
        setIdToken(response.result.token)

        props.setLoggedin([response.result.token, response.user.displayName])
    };
    return (
        <React.Fragment>
			<div className={'container login__container'}>
			<div class="site-logo">
							<a href="https://www.ghanemforwarding.com/">
								<img src={companyLogo} alt="" />
							</a>
						</div>
				<div className={'login'}>
					<div className={'login__div login__div__header'}>Log in</div>
						<div className={'login__div login__div__username'}>
							<label className={'login__label login__label__username'} htmlFor={"username"}>Username</label>
							<input className={'login__input login__input__username'}
								name="username"
								type="text"
								value={username}
								onChange={(event) => setUsername(event.target.value)}
							/>
						</div>
						<br />
						<div className={'login__div login__div__password'}>
							<label className={'login__label login__label__password'} htmlFor={"username"}>Password</label>
							<input
								className={'login__input login__input__password'}
								name="username"
								type="password"
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
						</div>
            		<div className="login__actions">
					<div className="login__remember">
					<input name="rememberMe" type='checkbox'
						checked={rememberMe}
						onChange={() => setRememberMe(!rememberMe)}/>
					<label htmlFor={'rememberMe'}>Remember Me</label>
					</div>

            <button className={'login__button'} onClick={() => handleLogin()}>Login</button>
			</div>
			</div>
			</div>

        </React.Fragment>
    );
}
