import React, {useEffect, useState} from "react"
import {
    getAllowedPortsAndCarrier,
    getConsigneeOrNotifyParty,
    getShippers,
    getVessels,
    postBookingSubmission
} from "../helpers/api";
import Select from "../components/Select";
import VinValidator from "../components/VinValidator";
import MappedTable from "../components/MappedTable";

export default function Booking(props) {
    const clearToken = props.clearToken;
    // API data
    const [vessels, setVessels] = useState({})
    const [shippers, setShippers] = useState({})
    const [consigneeOrNotifyParty, setConsigneeOrNotifyParty] = useState({})
    const [allowedPortsAndCarriers, setAllowedPortsAndCarriers] = useState({})
    const [errorField, setErrorField] = useState()
    const [editVehicle, setEditVehicle] = useState({})

    //User Choices
    const [shipperGuid, setShipperGuid] = useState("")
    const [ports, setPorts] = useState({origin: null, destination: null})
    const [bookingInformation, setBookingInformation] = useState({})
    const [carrierGuid, setCarrierGuid] = useState(null)
    const [vehicles, setVehicleInfo] = useState({})
    const [consigneeGuid, setConsigneeGuid] = useState("")
    const [notifyPartyGuid, setNotifyPartyGuid] = useState("")
    const [agreement, setAgreement] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            setShippers(await getShippers().catch(() => {
                localStorage.removeItem('token')
                clearToken()
            }))
            setAllowedPortsAndCarriers(await getAllowedPortsAndCarrier({}).catch(() => null))
            setConsigneeOrNotifyParty(await getConsigneeOrNotifyParty().catch(() => null))
        }
        fetchData()

    }, [clearToken])

    useEffect(() => {

        const fetchData = async () => {
            const response = await getAllowedPortsAndCarrier({
                carrierguid: carrierGuid,
                originportcode: ports.origin,
                destinationportcode: ports.destination
            })

            setAllowedPortsAndCarriers(response)

            const vesselResponse = await getVessels({
                carrierguid: carrierGuid,
                originportcode: ports.origin,
                destinationportcode: ports.destination
            })
            if (vesselResponse.vessels) {
                setVessels(vesselResponse.vessels.map(vessel => {

                    const loadingDate = new Date(vessel.loadingDate)
                    vessel.loadingDate = formatDate(loadingDate)

                    const cutOffDate = new Date(vessel.cutOffDate)
                    vessel.cutOffDate = formatDate(cutOffDate)

                    const dischargeDate = new Date(vessel.dischargeDate)
                    vessel.dischargeDate = formatDate(dischargeDate)

                    return vessel
                }))
            }

        }
        if (shipperGuid || carrierGuid) {
            fetchData()
            return
        }
        if (ports.origin && ports.destination) {
            fetchData()
        }


    }, [shipperGuid, ports, carrierGuid])

    const formatPortsData = data => {
        return data ?
            data.map(port => {
                return {text: port.name, value: port.code}
            })
            :
            {}
    }

    const getPortNameFromCode = (code, data) =>{
        return data.filter((port =>{
            return port.code === code
        }))[0].name
    }

    const formatDate = date => {
        return `${date.getMonth() + 1}/${date.getDay() + 1}/${date.getFullYear()}`
    }

    const addVehicleInfo = vehicleInfo => {
        const newVehicles = JSON.parse(JSON.stringify(vehicles))
        newVehicles[vehicleInfo.vin] = vehicleInfo
        setVehicleInfo(newVehicles)
        setEditVehicle({})
    }

    const handleSubmitBooking = async (data) => {
        if (!agreement) {
            alert("You must accept the terms")
            return
        }
        setErrorField('')

        const formData = {
            carrierGuid,
            shipperGuid,
            consigneeGuid,
            notifyPartyGuid,
            originPortCode: ports.origin,
            destinationPortCode: ports.destination,
            vehicles: Object.values(vehicles)
        }
        const requiredFields = ['shipperGuid', 'consigneeGuid', 'notifyPartyGuid', 'originPortCode', 'destinationPortCode', 'carrierGuid',]

        if (!requiredFields.every(requiredField => {
            const isValid = Boolean(formData[requiredField])
            if (!isValid) {
                setErrorField(requiredField)
            }
            return isValid
        })) {

            return
        }

        if (!data.vehicles.length) {
            alert('Please add at least one vehicle')
            return
        }

        const response = await postBookingSubmission(formData).catch(err => {
            setErrorField(err.code.slice(2,))
        })

        response && alert(response.result.resultMessage)
    }

    const choosePorts = () => {
        return (
			<div className="container new-booking__container">
            <fieldset>
				<h2>Available Ports</h2>
                <div className="select-container">
                    <label htmlFor="originPort">Origin Port</label>
                    <Select
                        values={formatPortsData(allowedPortsAndCarriers.originPorts)}
                        onValueChange={value => {
                            const newPorts = {destination: ports.destination, origin: value}
                            setPorts(newPorts)
                            setBookingInformation({
                                originPort : getPortNameFromCode(value, allowedPortsAndCarriers.originPorts),
                                ...bookingInformation
                            })
                        }}
                        selectedValue={ports.origin || ""}
                        name={'originPort'}
                    />
                </div>
                <div className="select-container">
                    <label htmlFor="destinationPort">Destination Port</label>
                    <Select values={formatPortsData(allowedPortsAndCarriers.destinationPorts)}
                            onValueChange={value => {
                                const newPorts = {destination: value, origin: ports.origin}
                                setPorts(newPorts)
                                setBookingInformation({
                                    destinationPort : getPortNameFromCode(value, allowedPortsAndCarriers.destinationPorts),
                                    ...bookingInformation
                                })
                            }}
                            name={'destinationPort'}
                            selectedValue={ports.destination || ""}
                    />
                </div>

                {(allowedPortsAndCarriers.carriers && ports.origin && ports.destination) &&
                <React.Fragment>
                    <div className={errorField === "carrierGuid" ? 'error' : errorField}>
                        <table>
                            <thead>
                            <th>Carrier</th>
                            <th>Available Schedule</th>
                            <th/>
                            </thead>
                            <tbody>
                            {allowedPortsAndCarriers.carriers.map(carrier => {
                                return (
                                    <tr>
                                        <td>{carrier.name}</td>
                                        <td><a target='_blank'
                                               href={`https://booking.ghanemforwarding.com/PDFs/${carrier.name.replace(/\s/g, '_')}.pdf`}>View
                                            Schedule</a></td>
                                        <td>
                                            <button onClick={() => {
                                                setCarrierGuid(carrier.carrierGuid)
                                                setBookingInformation({
                                                    carrierName: carrier.name,
                                                    ...bookingInformation
                                                })
                                            }}>Create Booking
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                </React.Fragment>
                }

            </fieldset>
			</div>
        )
    }

    const completeBooking = () => {
        return (
            <>
				<div className='container new-booking__container'>
					<div className='booking-information card'>
					<div className='card-header'>
						<h3>Booking Information</h3>
						</div>
						<div className='card-body'>
                <fieldset className='bookingInformation'>
                    <div>
                        <label htmlFor='carrierName'>Carrier Name</label><input disabled={true} type='text' name='carrierName' value={bookingInformation.carrierName}/>
                    </div>
                    <div>
                        <label htmlFor='originPort'>Origin Port</label><input disabled={true} type='text' name='originPort' value={bookingInformation.originPort}/>
                    </div>
                    <div>
                        <label htmlFor='destinationPort'>Destination Port</label><input disabled={true} type='text' name='destinationPort' value={bookingInformation.destinationPort}/>
                    </div>
					<button onClick={() =>{
                        setPorts({destination: null, origin:null})
                        setCarrierGuid(null)
                    }}>Change Ports or Carrier</button>
					
                </fieldset>
				</div>
				</div>
                <fieldset class="party-selection">
                    <div className={errorField === "shipperGuid" ? 'error' : errorField}>
                        <label htmlFor="shipperGuid">Please Choose a shipper</label>
                        <Select values={shippers} onValueChange={value => setShipperGuid(value)}
                                selectedValue={shipperGuid || ""} name={'shipperGuid'}/>
                    </div>
                    <div className={errorField === "consigneeGuid" ? 'error' : errorField}>
                        <label htmlFor="consigneeGuid">Please Choose a Consignee</label>
                        <Select values={consigneeOrNotifyParty} onValueChange={value => setConsigneeGuid(value)}
                                selectedValue={consigneeGuid || ""} name={'consigneeGuid'}/>
                    </div>
                    <div className={errorField === "notifyPartyGuid" ? 'error' : errorField}>
                        <label htmlFor="notifyPartyGuid">Please Choose a Notify Party</label>
                        <Select values={consigneeOrNotifyParty} onValueChange={value => setNotifyPartyGuid(value)}
                                selectedValue={notifyPartyGuid || ""} name={'notifyPartyGuid'}/>
                    </div>
                </fieldset>
				<div className='card new-booking__vehicles'>
				<div className='card-header'>
					<h3>Add Vehicles</h3>
				</div>
				<div className='card-body'>
				<fieldset className="vin-validator">
                    <label htmlFor="vinValidator">Vin Number</label>
                    <VinValidator
                        onValid={vehicleInfo => addVehicleInfo(vehicleInfo)}
                        name={'vinValidator'}
                        editVehicle={editVehicle}
                    />
                    {Object.keys(vehicles).length > 0 &&
                    <MappedTable
                        data={Object.values(vehicles).map(vehicle => {
                            vehicle.dimensions = `${vehicle.length} (in) x ${vehicle.width} (in) x ${vehicle.height} (in)`
                            return vehicle
                        })}
                        propertyNames={["description", "vin", "dimensions", "weight"]}
                        buttons={[
                            {
                                text: 'Edit',
                                clickHandler: vehicleToEdit => {
                                    setEditVehicle(vehicleToEdit)
                                }
                            },
                            {
                                text: 'Delete',
                                clickHandler: vehicleToDelete => {
                                    const vehicleCopy = JSON.parse(JSON.stringify(vehicles))
                                    delete vehicleCopy[vehicleToDelete.vin]
                                    setVehicleInfo(vehicleCopy)
                                }
                            }
                        ]}

                    />
                    }

                </fieldset>
				</div>
				</div>
                {new URL(window.location.href).searchParams.get('debug') &&
                <fieldset>
                    <h1>Submission Status</h1>
                    <ol>
                        <li> "carrierGuid": {carrierGuid}</li>
                        <li> "shipperGuid": {shipperGuid}</li>
                        <li> "consigneeGuid": {consigneeGuid}</li>
                        <li> "notifyPartyGuid": {notifyPartyGuid}</li>
                        <li> "originPortCode": {ports.origin}</li>
                        <li> "destinationPortCode": {ports.destination}</li>
                        <li> "vehicles": {Object.keys(vehicles)} {Object.keys(vehicles).length}</li>
                    </ol>
                </fieldset>
                }
				<div className="card">
                <fieldset>
				<div className="card-header">
                    <h3>Terms and Conditions</h3>
					</div>
					<div className="card-body">
                    <p>
                        Ghanem Forwarding LLC agrees to provide, and customer agrees to receive the services of Ghanem
                        Forwarding LLC according to the following terms and conditions: By accepting this dock receipt,
                        you represent and warrant that this dock receipt does not refer to any flooded, submerged, or
                        otherwise water damaged vehicles or vehicles that could be a potential fire hazard
                        (collectively, “Prohibited Vehicles”), and you agree to indemnify, defend, and hold harmless
                        Ghanem from and against all claims, liabilities, losses, diminution in value, damages, costs,
                        expenses, penalties, fines, deficiencies, and judgments suffered or incurred by Ghanem
                        (including amounts paid in settlement, costs of investigation, and reasonable attorneys’ fees
                        and expenses), whenever arising or incurred, whether or not involving a third-party claim,
                        arising out of or relating to any Prohibited Vehicles.” Customer also agrees that Vehicles will
                        be delivered with NO Goods or Additional items inside. Customer agrees that shipments will not
                        contain any unauthorized explosives, destructive devices or hazardous materials and consents to
                        search of any shipments. Customer understands that Titles will only be mailed upon specific
                        request and after mailing fees paid. Customer understands freight payment is due by the shipment
                        arrival date at destination per terms of invoice. Customer understands that Ghanem Forwarding
                        LLC limits its liability to no more than $50.00 USD per consignment. Customer understands that
                        Ocean Carriers normally do not offer any insurance coverages on cargo and limits their liability
                        to their Bill of Lading Terms, which is usually no more than $400 in case of total loss
                        only.*For Lagos, Nigeria shipments, Customer agrees to pay all outstanding charges in full
                        within 60 days of US Port manifest email, otherwise cargo is subject to auction automatically in
                        Lagos to cover Ocean freight charges and customer will still be held responsible for any
                        additional charges for services requested in the USA. Customer is responsible to verify the
                        accuracy of ALL information listed on each dock receipt prior to the delivery of cargo to the
                        terminal. (i.e. Port of Loading, Foreign Port of Discharge, Consignee and Notify Party). The
                        Buyer listed on the title must match the shipper on the dock receipt. Declared Vehicles Values
                        Must be true and accurate as they will be filed with US Customs as such. Customs Rejections are
                        subject to $50 Fee and port storage at $5 daily from day of delivery through customs clearance
                        date ($7.50 daily if over 60 days on customs hold), based on Customs Regulations per port.
                    </p>
                    <p>
                        Customer is also responsible for running/operable status of cargo when delivered. If cargo is
                        not in running condition during loading and additional loading assistance is needed, Non Runner
                        Fees / Forklift Fees will be applied to the shipment per port regulations.
                    </p>
                    <p>
                        Invoices are based strictly on Vehicle Volume (length, width, height, & weight) and
                        running/non-running status as delivered on terminal and loaded on vessel, any estimated quotes
                        are not binding.
                    </p>
                    <p>
                        Vehicles will be loaded based on when delivered and cleared customs; carrier schedules subject
                        to change and cannot be guaranteed.
                    </p>
					</div>
                </fieldset>
				</div>
                <fieldset>

                    <h1>Agreements</h1>
                    <input name="rememberMe" type='checkbox'
                           checked={agreement}
                           onChange={() => setAgreement(!agreement)}/>
                    <label>I have read and agreed to the terms and conditions bound to this order.</label>
                </fieldset>
                <fieldset>
                    <button onClick={() => handleSubmitBooking({
                        carrierGuid,
                        shipperGuid,
                        consigneeGuid,
                        notifyPartyGuid,
                        originPortCode: ports.origin,
                        destinationPortCode: ports.destination,
                        vehicles: Object.values(vehicles)
                    }).catch(r => {
                        console.log(r)
                    })}>Submit
                    </button>
                </fieldset>
				</div>
            </>
        )
    }

    return (

        <div>
            {!(ports.origin && ports.destination && carrierGuid) ?
                <div>
                    {choosePorts()}
                </div>
                :
                <div>
                    {completeBooking()}
                </div>


            }
        </div>
    )
}
