import React from "react"

const Select = ({ values, onValueChange, selectedValue, ...rest }) => {
    if (!Object.keys(values).length){
        return <div>Loading</div>
    }
    return (
        <select
            defaultValue={selectedValue}
            onChange={({ target: { value } }) => onValueChange(value)}
            {...rest}
        >
            <option disabled={true} value={""}/>
            {values.map(({value, text}) => (
                <option key={value} value={value}>
                    {text}
                </option>
            ))}
        </select>
    );
};

export default Select
