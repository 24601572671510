import React, {useEffect, useState} from "react"

const columnNames = {
    bookingNumber: 'Booking Number',
    vinNumber: 'VIN Number',
    status: 'Status',
    createdOn: 'Created On',
    description: 'Description',
    vin: 'VIN',
    weight: 'Weight (lbs)',
    dimensions: 'Dimensions (length x width x height)'
}
const MappedTable = ({data = [], propertyNames, showSearch, ...props}) => {

    const [filteredData, setFilteredData] = useState([])
    const [sortField, setSortField] = useState('createdOn')
    const [sortDirection, setSortDirection] = useState(-1)

    useEffect (()=>{
        const tempData = data.map(v =>
            Object.keys(v)
                .filter(k => propertyNames.includes(k))
                .reduce((acc, key) => ((acc[key] = v[key]), acc), {})
        )
        tempData.sort((a, b) => {
            return a[sortField] > b[sortField] ? sortDirection : -sortDirection
        })

        setFilteredData(tempData)

    }, [data, setFilteredData, propertyNames, sortField, sortDirection])

    const handleSearchText = event =>{

        const searchText = event.target.value
        if (searchText.length > 3){
            setFilteredData( filteredData.filter(v =>{
                    return Object.values(v).some(val => val && String(val).toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                })
            )
        }else{
            setFilteredData(data)
        }
    }
    if (!data){
        return (
            <div>
                LOADING
            </div>
        )
    }

    return (
        <div className="mapped-table">
			<div className="container">
			
            {showSearch &&
				<div className="mapped-table__search">
                <>
                <label htmlFor='searchText'>Search</label>
                <input type='text' onChange={event => handleSearchText(event)} name='searchText'/>
                </>
				</div>
            }
        <table>
            <thead>
            <tr>
                {propertyNames.map(val => (
                    <th
                        key={`h_${val}`}
                        className={`${sortField === val && 'selected'}`}
                        onClick={()=>{
                            if (sortField === val){
                                setSortDirection(sortDirection === 1 ? -1 : 1)
                            } else{
                                setSortField(val)
                            }

                        }}
                    >
                        {columnNames[val] || val}
                        {sortField === val && (sortDirection === 1 ? String.fromCharCode(9660): String.fromCharCode(9650))}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {filteredData.map((val, i) => (
                <tr key={`i_${i}`}>
                    {propertyNames.map(p => (
                        <td key={`i_${i}_${p}`}>{val[p]}</td>
                    ))}
                    {props.buttons &&
                        props.buttons.map(button =>{
                            return (
                                <td>
                                    <button onClick={() => {
                                        button.clickHandler(data[i])
                                    }}>
                                        {button.text}
                                    </button>
                                </td>
                            )
                        })
                    }
                    {typeof props.editFunction === "function" &&
                        <td>
                            <button onClick={() => {
                                props.editFunction(data[i])
                            }}>Edit</button>
                        </td>
                    }
                    {typeof props.deleteFunction === "function" &&
                    <td>
                        <button onClick={() => {
                            props.deleteFunction(data[i])
                        }}>Delete</button>
                    </td>
                    }
                </tr>
            ))}

            </tbody>
        </table>
        </div>
		</div>
    );
};

export default MappedTable
