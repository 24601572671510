import React, {useEffect} from 'react'
import { useState } from "react";
import {
    getAuctions,
    validateVin
} from "../helpers/api";
import getStates from "../constants/states";
import Select from "./Select";
import Modal from "./Modal";
import MappedTable from "./MappedTable";

const labels = {
    'model': 'Model',
    'height': 'Height (in)',
    'length': 'Length (in)',
    'width': 'Width (in)',
    'weight': 'Weight (lbs)',
    'name': 'Name',
    'phone': 'Phone',
    'address': 'Address',
    otherAuction: 'Other Auction',
    lotNumber: 'Lot Number',
    buyerName: 'Buyer Name',
    buyerNumber: 'Buyer Number'
}

const locationTypes = [
    {value:'residential', text:"Resident or Business"},
    {value:'auction', text:"Auction"}
]

export default function VinValidator({editVehicle, onValid, name}) {
    const [vin, setVin] = useState("");
    const [auctions, setAuctions] = useState([])

    const [vehicleInfo, setVehicleInfo] = useState({})
    const [isTowing, setIsTowing] = useState()
    const [towLocation, setTowLocation] = useState()

    useEffect(() => {
        const fetchData = async () => {
            setAuctions(await getAuctions({}).catch(() => {}))
        }
        fetchData()

    }, [])

    useEffect (() =>{
        setVehicleInfo(editVehicle)
    }, [editVehicle])

    const checkVin = async () =>{
        const response = await validateVin(vin)
        if (response.vehicleInfo){
            setVehicleInfo(response.vehicleInfo)
        }else{
            alert("Invalid Vin")
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const toReturn = JSON.parse(JSON.stringify(vehicleInfo))
        const formData = new FormData(e.target)

        for (const [key, value] of formData.entries()) {

            const parts = key.split('__')
            if (parts.length === 1){
                toReturn[key] = value === 'on' ? true : value
            }else{
                toReturn[parts[0]][parts[1]] = value === 'on' ? true : value
            }
        }

        onValid(toReturn)
        setVin("")
        setVehicleInfo({})
    }

    const getModal = () =>{
        return (
            <Modal  show={Object.keys(vehicleInfo || {}).length > 0} handleClose={e => {
                setVin("")
                setVehicleInfo({})
            }}>
				<div className={'modal-body'}>
				
                <fieldset className={'fieldset__specifications'}>
				<div className={'card'}>
				<div className={'card-header'}>
                    <h3 className={'fieldset__header fieldset__header__specification'}>Specifications</h3>
					</div>
					<div className={'card-body'}>
                    <div className={'vehicleInfo vehicleInfo__disabled'}>
                        <label htmlFor={'vehicleYear'}>Year</label>
                        <input value={vehicleInfo.year} disabled={true} name={'vehicleYear'} type={'text'}/>
                    </div>
                    <div className={'vehicleInfo vehicleInfo__disabled'} >
                        <label htmlFor={'vehicleMake'}>Make</label>
                        <input value={vehicleInfo.make} disabled={true} name={'vehicleMake'} type={'text'}/>
                    </div>
                    {['model', 'length', 'width', 'height', 'weight'].map((field)=>{

                        return (
                            <div className={'vehicleInfo vehicleInfo__editable'}>
                                <label htmlFor={`vehicle${field}`}>{labels[field]}:</label>
                                <input defaultValue={vehicleInfo[field]} name={`vehicle${field}`} type={'text'}/>
                            </div>
                        )
                    })}
					</div>
							</div>
                </fieldset>
		

                <fieldset className={'fieldset__additionalInfo'}>
				<div className={'card'}>
				<div className={'card-header'}>
                    <h3 className={'fieldset__header fieldset__header__specification'}>Additional Info</h3>
</div>
<div className={'card-body'}>
                    <div className={'vehicleInfo vehicleInfo__editable'}>
                        <label htmlFor={'eei__title'}>Title: </label>
                        <input defaultValue={vehicleInfo?.eei ? vehicleInfo.eei.title : ""} name={`eei__title`} type={'text'}/>
                    </div>
                    <div className={'vehicleInfo'}>
                        <label htmlFor={'eei__state'}>State: </label>
                        <Select values={getStates()}
                                name={'eei__state'}
                                selectedValue={""}
                                onValueChange={()=> null}
                        />
                    </div>
                    <div className={'vehicleInfo vehicleInfo__editable'}>
                        <label htmlFor={'eei__value'}>Value: </label>
                        <input defaultValue={vehicleInfo.eei ? vehicleInfo.eei.value : ""} name={`eei__value`} type={'number'}/>
                    </div>

                    <div className={'vehicleInfo vehicleInfo__editable'}>
                        <label htmlFor={'eei__nonRunner'}>NonRunner: </label>
                        <input defaultChecked={vehicleInfo.eei ? vehicleInfo.eei.nonRunner : false} name={`eei__nonRunner`} type={'checkbox'}/>
                    </div>

                    <div className={'vehicleInfo vehicleInfo__editable'}>
                        <label htmlFor={'eei__forkLift'}>ForkLift: </label>
                        <input defaultChecked={vehicleInfo.eei ? vehicleInfo.eei.forkLift : false} name={`eei__forkLift`} type={'checkbox'}/>
                    </div>

                    <div className={'vehicleInfo vehicleInfo__editable'}>
                        <label htmlFor={'inlandTowing__isRequested'}>Request Inland Towing: </label>
                        <input defaultChecked={vehicleInfo.eei ? vehicleInfo.eei.isRequested : false} name={`inlandTowing__isRequested`} type={'checkbox'} onChange={event=>{
                            setIsTowing(event.target.checked)
                        }}/>
                    </div>

                    {isTowing &&
                    <fieldset>
                        <div>
                            <label htmlFor={'inlandTowing__location'}>Location: </label>
                            <Select values={locationTypes}
                                    onValueChange={value => {
                                        setTowLocation(value)
                                    }}
                                    name={'inlandTowing__location'}
                                    selectedValue={towLocation || ""}
                            />
                        </div>


                        {towLocation &&
                        towLocation === 'auction' ?
                            <div>
                                <div>
                                    <label htmlFor={'inlandTowing__auction'}>Auction: </label>
                                    <Select values={auctions}
                                            name={'inlandTowing__auction'}
                                    />
                                </div>
                                {['otherAuction', 'lotNumber', 'buyerName', 'buyerNumber'].map((field) =>{
                                    return (
                                        <div className={'vehicleInfo vehicleInfo__editable'}>
                                            <label htmlFor={`inlandTowing__${field}`}>{labels[field]}:</label>
                                            <input  defaultValue={vehicleInfo.inlandTowing && vehicleInfo.inlandTowing[field]} name={`inlandTowing__${field}`} type={'text'}/>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div>
                                {['name', 'phone', 'address'].map((field) =>{
                                    return (
                                        <div className={'vehicleInfo vehicleInfo__editable'}>
                                            <label htmlFor={`inlandTowing__${field}`}>{labels[field]}:</label>
                                            <input  defaultValue={vehicleInfo.inlandTowing && vehicleInfo.inlandTowing[field]} name={`inlandTowing__${field}`} type={'text'}/>
                                        </div>
                                    )
                                })}
                            </div>
                        }

                    </fieldset>
                    }
					</div>
					</div>
                </fieldset>
				</div>

                <input type='submit' value='Add Vehicle' className={'button'}></input>
            </Modal>
        )
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
            <fieldset>
                <input
                    name={name}
                    value={vin}
                    onChange={(event) => setVin(event.target.value)}
                />
                <button type="button" className={'vinValidator vinValidator__button'} onClick={() => checkVin()}>Validate Vin Number</button>
            </fieldset>
                {getModal()}
            </form>
        </div>
    )
}
