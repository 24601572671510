import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import InTransit from "./container/InTransit";

document.getElementById('root') && ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

document.getElementById('inTransit') && ReactDOM.render(
    <React.StrictMode>
        <InTransit />
    </React.StrictMode>,
    document.getElementById('inTransit')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
