import React from 'react'
import './App.css';
import { useState } from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Login from "./components/Login";
import {checkForToken} from "./utils/login";
import BookingContainer from "./container/BookingContainer";
import Booking from "./components/Booking";
import Welcome from "./components/Welcome";
import AccountContainer from "./container/AccountContainer";
import ResetPasswordContainer from "./container/resetPasswordContainer";
import SetNewPasswordContainer from "./container/setNewPasswordContainer";
import companyLogo from './assets/img/logo.png';

function App() {
    const storedToken = checkForToken()
    const [token, setToken] = useState(storedToken)
    const [userName, setUserName] = useState('')

  return (
    <div className="App">

        {token
        ?
            <Router>
				<div className="welcome-notice">
					<div class="container">
						<span>Welcome {userName}</span>
					</div>
				</div>
                <header class="site-header">
					<div class="container">
						<div class="site-logo">
							<a href="https://www.ghanemforwarding.com/">
								<img src={companyLogo} alt="" />
							</a>
						</div>
                    <div className="site-navigation">
                    <nav class="main-nav">
                        <Link to="/">Home</Link>

                        <Link to="/booking">Booking</Link>

                        <Link to="/account">My Account</Link>

                        <Link to="/"
                              onClick={() => {
                                  setToken(null)
                                  localStorage.removeItem('token')
                              }}
                        >
                            Logout
                        </Link>
						</nav>
						</div>
						</div>
                </header>
                    <Switch>
                        <Route path="/booking/create">
                            <Booking />
                        </Route>
                        <Route path="/booking">
                            <BookingContainer />
                        </Route>
                        <Route path="/account">
                            <AccountContainer />
                        </Route>
                        <Route path="/">
                            <Welcome />
                        </Route>
                    </Switch>
            </Router>
        :
            <Router>
                <div>
                    <Switch>
                        <Route path="/setNewPassword">
                            <SetNewPasswordContainer/>
                        </Route>
                        <Route path="/resetPassword">
                            <ResetPasswordContainer userName={userName}/>
                        </Route>
                        <Route path="/">
                            <Login setLoggedin={([token, username]) => {
                                setToken(token)
                                setUserName(username)
                            }}
                                   clearToken={() => setToken(null)}
                            />
                            <nav className="login__forgot">
                                <Link to="/resetPassword">Forgot your password?</Link>
                            </nav>
                        </Route>

                    </Switch>
                </div>
            </Router>



        }

    </div>
  );
}

export default App;
