import React, {useState} from "react";
import {completePasswordReset} from "../helpers/api";

export default function SetNewPasswordContainer(props) {

    const [email, setEmail] = useState((new URL(document.location)).searchParams.get('email'))
    const [newPassword, setNewPassword] = useState('')
    const [resetToken, setResetToken] = useState((new URL(document.location)).searchParams.get('resetToken'))

    const resetPassword = async () =>{
        console.log('requesting New Password')
        const response = await completePasswordReset({resetToken, email, newPassword})
        console.log(response)
        switch (response.result.resultCode) {
            case 3:
                alert('unable to reset password.  Please try again')
                break
            default:
                console.log('response:', response)

        }

    }
    return (
        <div>

        <fieldset>
            <label className={'login login__label login__label__email'} htmlFor={"emailAddress"}>Email</label>
            <input className={'login login__input email'}
                   name="emailAddress"
                   type="text"
                   value={email}
                   onChange={(event) => setEmail(event.target.value)}
            />
            <label className={'login login__label login__label__newPassword'} htmlFor={"newPassword"}>New Password</label>
            <input className={'login login__input login__input__newPassword'}
                   name="newPassword"
                   type="password"
                   value={newPassword}
                   onChange={(event) => setNewPassword(event.target.value)}
            />
            <label className={'login login__label login__label__resetToken'} htmlFor={"resetToken"}>Reset Token</label>
            <input className={'login login__input login__input__resetToken'}
                   name="resetToken"
                   type="text"
                   value={resetToken}
                   onChange={(event) => setResetToken(event.target.value)}
            />
            <button onClick={() =>resetPassword()}>Submit</button>
        </fieldset>
    </div>
    )
}