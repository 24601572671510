import MappedTable from "../components/MappedTable";
import {useEffect, useState} from "react";
import {getBooked, searchShipments} from "../helpers/api";
import {useHistory} from "react-router-dom";
import Modal from "../components/Modal";

export default function BookingContainer(props) {

    const [booked, setBooked] = useState({})
    const [vinToSearch, setVinToSearch] = useState('')
    const [vinResult, setVinResult] = useState({})

    let history = useHistory();

    useEffect( () => {
        const fetchData = async () => {
            setBooked(await getBooked())

        }
        fetchData()

    }, [])

    const bookedHeaders = ['createdOn', 'bookingNumber', 'vinNumber', 'status' ]

    return (

        <div>
            <div>
                <div className={'title'}><h1>Booking</h1></div>
                <button onClick={() => history.push('/booking/create')}>Create new Booking</button>
            </div>
            <div>
                <div className={'title'}><h2>Search Bookings</h2></div>
				<div className={'vinSearch'}>
                <label htmlFor='vinSearch'>Vin Number:</label>
                <input
                    type='text'
                    name='vinSearch'
                    value={vinToSearch}
                    onChange={event => {setVinToSearch(event.target.value)}}
                />

                <button onClick={async () => {
                    const results = await searchShipments({vin: vinToSearch})
                    console.log(results)
                    setVinResult(results)
                }}>Search</button>
				</div>
                <Modal  show={Object.keys(vinResult || {}).length > 0} handleClose={ () => {
                    setVinToSearch("")
                    setVinResult({})
                }}>
                    <div>
                        <MappedTable data={vinResult.shipments} propertyNames={bookedHeaders} />
                    </div>
                </Modal>
            </div>
            <div>
                <div className={'title'}><h2>My Bookings</h2></div>
                <MappedTable
                    data={booked.shipments}
                    propertyNames={bookedHeaders}
                    showSearch={true}
                />
            </div>
        </div>
    )
}